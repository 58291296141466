import logo from './logo.svg';
import wordmark from './wordmark.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={wordmark} className="App-logo" alt="logo" />
        <p>
          
        </p>
      </header>
    </div>
  );
}

export default App;
